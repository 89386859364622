import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ProjectAdvancedFilter } from "../../components/Project/ProjectAdvancedFilter/ProjectAdvancedFilter";
import { ProjectAwardTooltipPopover } from "../../components/Project/ProjectAwardTooltipPopover/ProjectAwardTooltipPopover";
import { ProjectCopyToTenantDialog } from "../../components/Project/ProjectCopyToTenantDialog/ProjectCopyToTenantDialog";
import { ProjectFilter } from "../../components/Project/ProjectFilter/ProjectFilter";
import { StatusFilter } from "../../components/Project/ProjectFilter/StatusFilter/StatusFilter";
import { ProjectKPISystemsDialog } from "../../components/Project/ProjectKPISystemsDialog/ProjectKPISystemsDialog";
import { ProjectSystemsDialog } from "../../components/Project/ProjectSystemsDialog/ProjectSystemsDialog";
import { PERMISSION_PROJECT_CREATE, PERMISSION_PROJECT_MIGRATION } from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { checkPopState } from "../../hooks";
import {
  deleteProject,
  deleteProjectKpiSystem,
  deleteProjectSystem,
  getProjectRatingStatusCount,
  resetProjectsPageState,
  searchProjects,
  useProjectDeleteKpiSystemResponse,
  useProjectDeleteResponse,
  useProjectDeleteSystemResponse,
  useProjectRatingStatusCountResponse,
  useProjectsPageLogic,
  useProjectsPageResponse,
  useProjectUserPermissionsResponse,
} from "../../hooks/project";
import { showError, showInfo } from "../../hooks/toast";
import { useTimeout } from "../../hooks/utils/useTimeout";

import PropTypes from "prop-types";
import { PlusSVG } from "../../svg";
import { processMessage } from "../../utils";
import "./Projects.scss";
import { ProjectsTable } from "./ProjectsTable";

const getSortValue = (sortValue) => {
  if (!sortValue?.field) return;
  return (sortValue.desc ? "-" : "") + sortValue.field;
};

export const ProjectsPage = () => {
  const { t } = useTranslation();
  const [, setSearchTimeout] = useTimeout();
  const response = useProjectsPageResponse();
  const countResponse = useProjectRatingStatusCountResponse();
  const [processed, setProcessed] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteResponse = useProjectDeleteResponse();
  const deleteProjectSystemResponse = useProjectDeleteSystemResponse();
  const deleteProjectKpiSystemResponse = useProjectDeleteKpiSystemResponse();
  const permissionsResponse = useProjectUserPermissionsResponse();

  const {
    searchValue,
    setSearchValue,
    filterValue,
    kendoFilters,
    setFilterValue,
    sortValue,
    filterValues,
    setFilterValues,
    page,
    rowsPerPage,
    setTotal,
    setPage,
    resetState,
    actionsPopoverProps,
    setActionsPopoverProps,
  } = useProjectsPageLogic();

  const pageRef = useRef(null);
  const [init, setInit] = useState(false);
  const [copyTenantData, setCopyTenantData] = useState(null);
  const [addSystemToProject, setAddSystemToProject] = useState(null);
  const [addKpiSystemToProject, setAddKpiSystemToProject] = useState(null);
  const [editPopoverTarget, setEditPopoverTarget] = useState(null);
  const [showTooltip, setShowTooltip] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [permissionsByProjectId, setPermissionsByProjectId] = useState({});
  const [permissionsProjectId] = useState(null);
  const [restrictedProjects, setRestrictedProjects] = useState(false);
  const projectPermissions = actionsPopoverProps?.projectId
    ? permissionsByProjectId[actionsPopoverProps.projectId]
    : null;
  const isDisabledActions = actionsPopoverProps?.accessRestricted && !projectPermissions?.permissionOwner;

  useEffect(() => {
    if (permissionsResponse.data && permissionsProjectId === actionsPopoverProps?.projectId) {
      setPermissionsByProjectId({
        ...permissionsByProjectId,
        [actionsPopoverProps?.projectId]: permissionsResponse.data,
      });
    }
  }, [permissionsResponse.data]);

  const load = () => {
    searchProjects({
      page: page + 1,
      itemsPerPage: rowsPerPage,
      sort: getSortValue(sortValue),
      name: searchValue,
      ratingStatus: filterValue,
      kendoFilters,
      filterValues,
      advancedFilters,
      restrictedProjects,
    }).catch((error) => {
      console.error(error);
      showError(t("project.error.loadList"));
    });
  };

  const loadCounts = () => {
    getProjectRatingStatusCount({
      name: searchValue,
      projectTypes: filterValues.projectTypes,
      sustainabilitySystemId: filterValues.sustainabilitySystems,
      kpiSystemId: filterValues.kpiSystems,
      advancedFilters,
      restrictedProjects,
    }).catch((error) => {
      console.error(error);
      showError(t("error.500"));
    });
  };

  useEffect(() => {
    if (!checkPopState()) {
      resetProjectsPageState();
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) load();
  }, [
    init,
    searchValue,
    sortValue,
    page,
    rowsPerPage,
    filterValue,
    restrictedProjects,
    filterValues,
    advancedFilters,
    kendoFilters,
    deleteResponse,
  ]);

  useEffect(() => {
    if (init) loadCounts();
  }, [
    init,
    searchValue,
    filterValues.projectTypes,
    filterValues.sustainabilitySystems,
    filterValues.kpiSystems,
    advancedFilters,
    restrictedProjects,
    kendoFilters,
  ]);

  useEffect(() => {
    if (!response.loading) {
      const responseData = response.data || {};
      const projects = responseData.content || [];
      const totalValue = responseData?.meta?.total || 0;
      const processedInner = new Array(page * rowsPerPage).concat(
        projects
          .map((item) => ({
            ...item,
            children: [
              ...item.ratingSystems.map((child) => ({ ...child, projectPermission: item.projectPermission })),
              ...item.kpiRatingSystems.map((child) => ({ ...child, projectPermission: item.projectPermission })),
            ],
          }))
          .concat(totalValue ? new Array(totalValue - page * rowsPerPage - projects.length) : [])
      );

      setProcessed(processedInner);
      setTotal(totalValue);
    }
  }, [response.loading, response.data]);

  const search = (value) => {
    if (response.loading) {
      response.abort();
    }
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const afterChange = () => (page === 0 ? loadCounts() : setPage(0));

  const deleteHandler = () => {
    setDeleteConfirmation(null);
    if (
      deleteConfirmation.dataItem?.sustainabilitySystemId ||
      deleteConfirmation.dataItem?.sustainabilitySystemDeletedTimeStamp
    ) {
      deleteProjectSystem(deleteConfirmation.dataItem?.projectId, deleteConfirmation.dataItem?.id).then(
        () => {
          showInfo(t("project.ratingSystemDeletedMessage"));
          afterChange();
        },
        () =>
          showError(
            processMessage(t("project.deleteRatingSystemError"), [
              t("ratingSystem.status." + deleteConfirmation.dataItem?.ratingStatus),
            ])
          )
      );
    } else if (deleteConfirmation.dataItem?.kpiSystemId) {
      deleteProjectKpiSystem(deleteConfirmation.dataItem?.projectId, deleteConfirmation.dataItem?.id).then(
        () => {
          showInfo(t("project.ratingSystemDeletedMessage"));
          afterChange();
        },
        () =>
          showError(
            processMessage(t("project.deleteRatingSystemError"), [
              t("ratingSystem.status." + deleteConfirmation.dataItem?.ratingStatus),
            ])
          )
      );
    } else {
      deleteProject(deleteConfirmation.dataItem?.id).then(
        () => {
          showInfo(t("project.deletedMessage"));
          afterChange();
        },
        () => showError(t("project.deleteError"))
      );
    }
  };

  const onAddSystems = () => {
    setAddSystemToProject(null);
    setAddKpiSystemToProject(null);
    afterChange();
  };

  const award = showTooltip?.data?.selectedAward;

  const isProject = (props) => {
    return props?.level?.length === 1;
  };

  const toggleRestrictedProjects = (value) => {
    setRestrictedProjects(value);
  };

  const loading =
    response.loading ||
    countResponse.loading ||
    deleteResponse.loading ||
    deleteProjectSystemResponse.loading ||
    deleteProjectKpiSystemResponse.loading;

  const hasSustainabilitySystem =
    deleteConfirmation?.dataItem?.sustainabilitySystemId ||
    deleteConfirmation?.dataItem?.sustainabilitySystemDeletedTimeStamp;
  const hasKpiSystem = deleteConfirmation?.dataItem?.kpiSystemId;

  const titleText =
    hasSustainabilitySystem || hasKpiSystem
      ? t("project.ratingSystemDeleteConfirmationTitle")
      : t("project.deleteConfirmationTitle");

  const bodyText =
    hasSustainabilitySystem || hasKpiSystem
      ? t("project.ratingSystemDeleteConfirmation")
      : t("project.deleteConfirmation");

  return (
    <div ref={pageRef} className="projects-page page-with-table">
      <div className="page-header large flex-row">
        <div className="flex-col title-box no-margin">
          <h1>{t("projects.title")}</h1>
        </div>
        <div className="flex-col search-box no-margin">
          <SearchIcon />
          <input
            type="text"
            key={resetState}
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t(restrictedProjects ? "projects.searchRestrictedPlaceholder" : "main.search")}
            onChange={(event) => search(event.target.value)}
          />
          <ProjectAdvancedFilter onChange={setAdvancedFilters} filters={advancedFilters} />
        </div>
        <div className="search-restricted">
          <FormControl fullWidth>
            <div>
              <Checkbox
                id="restrictedProjectsCheckbox"
                checked={!!restrictedProjects}
                label={t("projects.searchRestricted")}
                onChange={(event) => toggleRestrictedProjects(event.target.checked)}
              />
              <label htmlFor="restrictedProjectsCheckbox">{t("projects.searchRestricted")}</label>
            </div>
          </FormControl>
        </div>
      </div>

      <div className="page-layout">
        <div className="flex-row-filter">
          <StatusFilter onChange={setFilterValue} value={filterValue} />
          <ProjectFilter onChangeFilters={setFilterValues} filters={filterValues} />
        </div>

        <LoadingOverlay spinner active={loading}>
          <ProjectsTable projects={processed} />
        </LoadingOverlay>
      </div>

      {hasPermission(PERMISSION_PROJECT_CREATE) && (
        <div className="fabs">
          <Fab type="button" color="primary">
            <NavLink to="/project/create" className="link">
              <PlusSVG />
            </NavLink>
          </Fab>
        </div>
      )}

      <ProjectAwardTooltipPopover
        award={award}
        setShowTooltip={setShowTooltip}
        showTooltip={showTooltip}
        container={pageRef?.current}
      />

      <Popover
        open={!!actionsPopoverProps?.target}
        anchorEl={actionsPopoverProps?.target}
        onClose={() => setActionsPopoverProps(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <LoadingOverlay className="auto-height" spinner active={permissionsResponse.loading}>
          {isProject(actionsPopoverProps?.props) ? (
            <>
              {!isDisabledActions ? (
                <NavLink to={"/project/" + actionsPopoverProps?.props?.dataItem?.id + "/edit"}>
                  <MenuItem onClick={() => setActionsPopoverProps(null)}>{t("project.editProject")}</MenuItem>
                </NavLink>
              ) : (
                <NavLink to={"/project/" + actionsPopoverProps?.props?.dataItem?.id}>
                  <MenuItem onClick={() => setActionsPopoverProps(null)}>{t("project.openProject")}</MenuItem>
                </NavLink>
              )}
              <MenuItem
                size="small"
                disabled={isDisabledActions}
                onClick={() => {
                  setAddSystemToProject(actionsPopoverProps?.props);
                  setActionsPopoverProps(null);
                }}
              >
                {t("groupingContext.addEvaluation")}
              </MenuItem>
              <MenuItem
                size="small"
                disabled={isDisabledActions}
                onClick={() => {
                  setAddKpiSystemToProject(actionsPopoverProps?.props);
                  setActionsPopoverProps(null);
                }}
              >
                {t("groupingContext.addKpiSystem")}
              </MenuItem>

              {hasPermission(PERMISSION_PROJECT_MIGRATION) && (
                <MenuItem
                  size="small"
                  disabled={isDisabledActions}
                  onClick={() => {
                    setCopyTenantData(actionsPopoverProps?.props);
                    setActionsPopoverProps(null);
                  }}
                >
                  {t("groupingContext.copyProjectToTenant")}
                </MenuItem>
              )}
              <MenuItem
                size="small"
                disabled={isDisabledActions}
                onClick={() => {
                  setDeleteConfirmation(actionsPopoverProps?.props);
                  setActionsPopoverProps(null);
                }}
              >
                {t("project.deleteProject")}
              </MenuItem>
            </>
          ) : (
            <MenuItem
              size="small"
              disabled={isDisabledActions}
              className="cy-delete-rs"
              onClick={() => {
                setDeleteConfirmation(actionsPopoverProps?.props);
                setActionsPopoverProps(null);
              }}
            >
              {actionsPopoverProps?.props?.dataItem?.kpiSystemId
                ? t("project.deleteKpiRatingSystem")
                : t("project.deleteRatingSystem")}
            </MenuItem>
          )}
        </LoadingOverlay>
      </Popover>

      <Popover
        open={!!editPopoverTarget}
        anchorEl={editPopoverTarget?.target}
        onClose={() => setEditPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <NavLink to={"/project/ratingSystem/" + editPopoverTarget?.props?.id + "/edit"}>
          <MenuItem onClick={() => setEditPopoverTarget(null)}>{t("project.editRatingSystem")}</MenuItem>
        </NavLink>
      </Popover>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={titleText}
        bodyText={bodyText}
        confirmText={t("main.delete")}
        color="secondary"
      />

      <ProjectSystemsDialog
        project={addSystemToProject}
        open={!!addSystemToProject}
        onClose={() => setAddSystemToProject(null)}
        onSave={onAddSystems}
      />

      <ProjectKPISystemsDialog
        project={addKpiSystemToProject}
        open={!!addKpiSystemToProject}
        onClose={() => setAddKpiSystemToProject(null)}
        onSave={onAddSystems}
      />

      <ProjectCopyToTenantDialog
        projectId={copyTenantData?.id}
        projectName={copyTenantData?.name}
        refreshProjectList={afterChange}
        open={copyTenantData}
        onClose={() => setCopyTenantData(null)}
      />
    </div>
  );
};

ProjectsPage.propTypes = {
  level: PropTypes.array,
};
